.profile {
    padding: 0 16px 10px 16px;
}

.profile-information-title {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.profile-information-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}

.profile-information-wrapper {
    padding-bottom: 5px;
}

.profile-buttons {
    padding-top: 10px;
}

.profile-button-wrapper {
    padding-bottom: 10px;
}