@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 24px;
  text-align: center;
  padding: 0 15px;
}

h2 {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  padding: 0 15px;
}