.history-empty {
    padding: 20px 20px 0px 20px;
}

.history-information-wrapper {
    padding: 8px 15px;
}

.history-payment-wrapper {
    border: 1px solid #5C5C5C;
    border-radius: 10px;
    display: flex;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
    justify-content: space-between;
    padding: 10px 20px;
}

.history-payment-header {
    color: #979797;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 2px;
}

.history-payment-value {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.history-amount-wrapper {
    padding: 20px 15px 0 0;
}

.history-amount-header {
    color: #979797;
    font-weight: 700;
    font-size: 22px;
    line-height: 16px;
    padding-bottom: 5px;
}

.history-amount-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
}