.profile-update-animal-header {
    padding-right: 4px;
}

.profile-edit-animal-header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 9px 0 5px;
}

.profile-edit-animal-header-wrapper-button {
    padding: 18px 15px 0 0;
}