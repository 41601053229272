.information-wrapper {
    padding: 10px 15px;
}

.list-of-animals-header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.list-of-animals-cancel-button {
    padding: 20px 10px 0 0;
}

.dog-box {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    width: 100%;
}

.dog-box-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}

.dog-box-weight {
    color: #979797;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}

.dog-action-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 20px;
}

.dog-add-button {
    text-align: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.dog-box-information {
    cursor: pointer;
}