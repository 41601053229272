.header {
    height: 113px;
    background: #F6E5DC;
}

.first-header1-image {
    img {
        width: 41px;
        height: 49px;
    }
}

.first-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 12px;
}

.second-header1,
.second-header2,
.first-header1 {
    cursor: pointer;
    display: flex;
}

.first-header-menu,
.second-header2 {
    cursor: pointer;
}

.header-title {
    font-size: 40px;
    font-weight: 700;
    padding-left: 7px;
}

.second-header {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
}

.second-header2-cart-number {
    font-weight: 700;
    padding-top: 6px;
}