.subscription-information-wrapper {
    padding: 0 14px 0 17px;
}

.profile-subscription-header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}

.profile-subscription-header-wrapper-button {
    padding: 18px 15px 0 0;
    text-align: right;
    width: 100%;
}

.subscription-box {
    display: flex;
    border: 1px solid #5C5C5C;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
    border-radius: 10px;
    width: 100%;
}

.subscription-header {
    font-size: 15px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
}

.subscription-value {
    font-size: 16px;
    font-weight: 700;
    word-break: break-all;
    color: #000000;
}

.subscription-text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 12px 0 12px 15px;
    width: 100%;
}

.subscription-buttons {
    display: block;
    text-align: center;
    padding: 10px 5px 0 5px;
}

.with-status {
    display: flex;
}

.subscription-edit-button {
    padding-bottom: 10px;
}