.loading {
    width: 100px;
    margin: 0 auto;
    animation: rotation 6s infinite linear;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}