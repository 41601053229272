.edit-profile-form-wrapper {
    padding: 5px 15px;
}

.edit-profile-buttons {
    display: flex;
    padding: 5px 15px;
    justify-content: right;
}

.submit-button {
    padding-left: 10px;
}