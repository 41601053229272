.animal-information-type {
    display: flex;
    justify-content: space-evenly;
}

.animal-information-type-or {
    padding: 8px 10px;
}

.add-animal-action-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
}

.weight-category-wrapper {
    div {
        padding: 8px 0;
    }
}

.minor-text {
    font-size: 14px;
}

.profile-add-animal-header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}

.profile-add-animal-header-wrapper-button {
    padding: 18px 15px 0 0;
}