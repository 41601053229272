.footer {
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.footer-logo {
    height: 27px;
    width: 33px;
    margin: 20px 0 0 10px;
    img {
        transform: rotate(260deg);
    }
}

.footer-header {
    display: flex;
    justify-content: center;
}

.footer-link {
    cursor: pointer;
}

.creator {
    display: flex;
    justify-content: center;
}