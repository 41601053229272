.login {
    text-align: center;
    padding: 34px 10px;
}

.login-header {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
}

.email-field {
    padding: 10px;
}

.password-field {
    padding: 10px;
}

.login-button {
    padding: 10px 10px 0 10px;
}