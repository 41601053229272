img {
    width: 100%;
}

.home-first-description {
    padding: 0 8px;
}

.home-third-button,
.home-second-button {
    padding: 5px 0;
    text-align: center;
    width: 100%;
}

.home-second-gift {
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    width: 100%;
}

.first-section-color,
.second-section-color {
    background-color: #FFFFFF;
}

.home-second-background {
    background-color: #F6E5DC;
}

.home-third-button {
    padding: 10px 0 15px 0;
}

.home-fix-image {
    margin-bottom: -7px;
}

.home-fourt-text-field {
    display: flex;
    justify-content: center;
}

.sign-up-button {
    padding: 0 0 0 5px;
    button {
        height: 56px;
    }
}

.footer-links {
    padding: 0 36px 20px 36px;
}