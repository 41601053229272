.header-with-title-and-button {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}

.header-text {
}

.button-with-action {
    padding: 15px 10px 0 0;
}